<template>
  <span
    class="price"
    v-text="formattedPrice"
  />
</template>

<script>
import {CustomerCurrencyFormat, DEFAULT_CUSTOMER_CURRENCY_FORMAT} from '~/assets/js/constants'
import {getCurrencySymbol} from '~/assets/js/utils/price_logic'
import {storeToRefs} from 'pinia'
import {useCartStore} from '@/store/cart'
import {useCustomerSettingsStore} from '@/store/customer-settings'

export default {
  name: 'PriceF',
  props: {
    price: {
      type: Number,
      default() {
        return null
      }
    },
    currency: {
      type: String,
      default() {
        return null
      }
    },
    onRequestIfZero: {
      type: Boolean,
      default() {
        return true
      }
    },
    hidePriceRequest: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  setup() {
    const {metainformation} = storeToRefs(useCartStore())
    const {currentCustomerSettings} = storeToRefs(useCustomerSettingsStore())

    return {
      metainformation,
      settings: currentCustomerSettings
    }
  },
  methods: {
    formatPrice(price, currencySetting, currency, currencySymbol, fractionDigits, locale) {
      let result = ''
      if (currencySetting !== null) {
        const enNumberFormat = new Intl.NumberFormat('en', {
          style: 'decimal',
          minimumFractionDigits: currencySetting?.minimumFractionDigits || fractionDigits,
          maximumFractionDigits: currencySetting?.maximumFractionDigits || fractionDigits,
          useGrouping: false
        })

        const enPrice = enNumberFormat.format(price)
        if (currencySetting?.minimumFractionDigits !== null) {
          result = this.settingAwarePrice(enPrice, currencySetting, currencySymbol)
        }
        else {
          result = enPrice + ' ' + currencySymbol
        }
      }
      else {
        const numberFormat = new Intl.NumberFormat(locale, {
          style: 'currency',
          currency,
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        })
        result = numberFormat.format(price)
      }
      return result
    },
    tryParseJSON(json, fallback) {
      try {
        return JSON.parse(json)
      }
      catch {
        return fallback
      }
    },
    settingAwarePrice(enPrice, currencySetting, currencySymbol) {
      const splittedPrice = enPrice.split('.')
      const preDecimals = splittedPrice[0]

      let formattedDecimals = []
      for (let i = preDecimals.length - 1, j = 0; i >= 0; i--, j++) {
        if (j % currencySetting?.groupSize === 0 && j !== 0) {
          formattedDecimals.push(currencySetting?.groupSeparator)
        }
        formattedDecimals.push(preDecimals[i])
      }
      return [...formattedDecimals].reverse().join('') + (currencySetting.minimumFractionDigits === 0 ? ' ' : (currencySetting?.separator + splittedPrice[1] + ' ')) + currencySymbol
    }
  },
  computed: {
    formattedPrice() {
      const currencyFormatSetting = this.settings?.find(sv => sv.translationKey === CustomerCurrencyFormat)
      const cleanValue = currencyFormatSetting ? currencyFormatSetting.customerSettingValue.replace(/\\/g, '') : null
      const currencySetting = cleanValue ? this.tryParseJSON(cleanValue, DEFAULT_CUSTOMER_CURRENCY_FORMAT) : DEFAULT_CUSTOMER_CURRENCY_FORMAT
      const currency = this.currency ?? this.metainformation?.currency
      const country = this.$i18n.locale.toLowerCase()
      const currencySymbol = getCurrencySymbol(country, currency)
      const fractionDigits = this.metainformation?.amountOfDecimals
      let price = this.price
      let formattedPrice = '-'
      if (!this.hidePriceRequest && price === 0 && this.onRequestIfZero) {
        formattedPrice = this.$t('onRequest')
      }
      else if (!this.onRequestIfZero && price === 0) {
        formattedPrice = '-'
      }
      else if (price !== null && !isNaN(price)) {
        formattedPrice = this.formatPrice(price, currencySetting, currency, currencySymbol, fractionDigits)
      }
      return formattedPrice
    }
  }
}
</script>
