import {ITEMS, CONFIGURATION_KEYS, INCOTERM_PICKUP_CUSTOMER_REGEXP} from '~/assets/js/constants'
import {usePlantStore} from '@/store/plants'
import {useConfigurationDataStore} from '@/store/configuration-data'
import {useCartStore} from '@/store/cart'

export default {
  methods: {
    getVariants() {
      return this.variants ? this.variants : []
    },
    getVariantText(variant) {
      if (!variant) {
        return ''
      }
      const packageUnitText = (`${variant.amount || ''} ${this.$tv(variant.unit, 'packageUnits')}`).trim()
      let variantText = ''
      if (variant.subType === 'PI' || variant.subType === 'PS') {
        variantText = `${this.$tv(variant.subType, 'variants')} ${packageUnitText ? '(' + packageUnitText + ')' : ''} ${variant?.sku ?? ''}`
      } else {
        variantText = `${this.$tv(variant.subType, 'variants')} ${variant?.sku ?? ''}`
      }
      return variantText.trim()
    },
    isPickupCustomerAddress(customer, addressCustomer) {
      const regex = INCOTERM_PICKUP_CUSTOMER_REGEXP
      return (customer?.incotermOfWe &&
        regex.test(customer.incotermOfWe) &&
        addressCustomer?.incotermOfWe &&
        regex.test(addressCustomer.incotermOfWe))
    }
  },
  computed: {
    validateAvailabilityDate() {
      let result = {
        type: ITEMS.VALIDATION_TYPES.OK,
        message: ITEMS.AVAILABILITY_DATE_MESSAGES.NONE
      }

      if (this.isLimitedAvailability) {
        result.type = ITEMS.VALIDATION_TYPES.NONE
        return result
      }

      // error tooltips
      if (this.availability === null) {
        result.type = ITEMS.VALIDATION_TYPES.ERROR
        result.message = ITEMS.AVAILABILITY_DATE_MESSAGES.NOT_LOADED
        return result
      }
      const today = new Date().setHours(0, 0, 0, 0)
      const maxDate = new Date().setFullYear( new Date().getFullYear() + 2)
      const dateCopy = this.availabilityDate ? new Date(this.availabilityDate.getTime()) : this.availabilityDate
      const availabilityDateTime = dateCopy
      if (availabilityDateTime <= today) {
        result.type = ITEMS.VALIDATION_TYPES.ERROR
        result.message = ITEMS.AVAILABILITY_DATE_MESSAGES.DATEPASTINVALID
        return result
      }

      if (availabilityDateTime > maxDate) {
        result.type = ITEMS.VALIDATION_TYPES.ERROR
        result.message = ITEMS.AVAILABILITY_DATE_MESSAGES.DATEFUTUREINVALID
        return result
      }

      // warning tooltips
      const isAvailable = this.availability.isAvailable ?? null
      if (isAvailable === false) {
        result.type = ITEMS.VALIDATION_TYPES.WARNING
      }

      return result
    },
    stockScheduleRows() {
      return this.availability?.stockAvailabilities ?? []
    },
    hasMultipleWarehouses() {
      if (!this.product) {
        return false
      }
      const plants = usePlantStore().allPlants?.filter(fw => fw.plant === this.product?.defaultPlantCode)
      const showAlternativePlants = useConfigurationDataStore().configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_ALTERNATIVE_PLANTS_KEY)
      return (plants && plants.length > 1) && showAlternativePlants?.value === 'True'
    },
    hasCertificateChange() {
      const showCertificateChange = useConfigurationDataStore().configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_CERTIFICATES_KEY)
      return (this.availableCertificates && this.availableCertificates.length >= 1) && showCertificateChange?.value === 'True'
    },
    hasVariantChange() {
      const variantList = this.product ? this.product.otherVariants : this.getVariants()
      const showVariantChange = useConfigurationDataStore().configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_VARIANTS_KEY)
      return (variantList && variantList.length >= 1) && showVariantChange?.value === 'True'
    },
    showShippingFee() {
      const showShippingFeeConfig = useConfigurationDataStore().configurations?.find(config => config.key === CONFIGURATION_KEYS.SHOW_SHIPPING_FEE_KEY)
      let customer = this.customerAddresses && this.customerAddresses.length > 0 ? this.customerAddresses[0] : useCartStore().selectedAddresses.invoice
      let delivery = this.deliveryAddresses && this.deliveryAddresses.length > 0 ? this.deliveryAddresses[0] : useCartStore().selectedAddresses.delivery
      return !(this.isPickupCustomerAddress(customer, delivery) && showShippingFeeConfig?.value === 'False')
    }
  }
}
